import { Town } from "~/types/Town";

export type TownResponse = {
  data: Town;
};

export type TownsResponse = {
  data: Town[];
};

export const useTownStore = defineStore("town", () => {
  const storeTown = ref<Town>();
  const storeTowns = ref<Town[]>();

  async function fetchTownByCountyCodeAndNameCode(
    countyCode: string,
    nameCode: string
  ): Promise<Town> {
    const { error, data } = await useFetch<TownResponse>(
      useJustHireUrl(
        `/api/towns/${countyCode}/${nameCode}/name-code`
      ),
      {
        headers: useJustHireHeaders(),
      }
    );

    if (error.value) {
      useHandleJHError(error);
    }

    const town = useKeysToCamel(data.value?.data) as Town;

    storeTown.value = town;

    return town;
  }

  async function fetchForCounty(countySlug: string): Promise<Town[]> {
    const { error, data } = await useFetch<TownsResponse>(
      useJustHireUrl(`/api/towns/for-county/${countySlug}`),
      {
        headers: useJustHireHeaders(),
      }
    );

    if (error.value) {
      useHandleJHError(error);
    }

    const towns = useKeysToCamel(data.value?.data) as Town[];

    storeTowns.value = towns;

    return towns;
  }

  return {
    town: storeTown,
    towns: storeTowns,
    fetchForCounty,
    fetchTownByCountyCodeAndNameCode,
  };
});
