import { Order } from "~~/types/Order";

export default function useBeginCheckoutGa(booking: Order): void {
  let items = [];

  booking.equipment.forEach((item) => {
    items.push({
      item_id: item.type.id,
      item_name: item.type.name,
      currency: "GBP",
      price: item.price,
      quantity: 1,
    });
  });

  if (!process.client) {
    return;
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: "begin_checkout",
    ecommerce: {
      items: items,
    },
  });
}
