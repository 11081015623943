export const useErrorStore = defineStore("errors", () => {
  const storeErrors = ref();

  function setErrors(errors: Array<any>): void {
    storeErrors.value = errors;
  }

  function clearErrors(): void {
    storeErrors.value = [];
  }

  function clearError(field: string): void {
    for (let key in storeErrors.value) {
      if (key === field) {
        delete storeErrors.value[key];
      }
    }
  }

  function hasErrors(): boolean {
    if (!storeErrors.value) {
      return false;
    }

    let errorsIsArray = Array.isArray(storeErrors.value);

    if (errorsIsArray && storeErrors.value.length > 0) {
      return true;
    }

    if (errorsIsArray && storeErrors.value.length === 0) {
      return false;
    }

    if (Object.keys(storeErrors.value).length === 0) {
      return false;
    }

    return true;
  }

  function hasError(field: string): boolean {
    if (!hasErrors()) {
      return false;
    }

    if (storeErrors.value[field] !== undefined) {
      return true;
    }
    return false;
  }

  function errorsFor(field: string): string {
    return storeErrors.value[field].join(",");
  }

  return {
    errors: storeErrors,
    setErrors,
    clearErrors,
    clearError,
    hasErrors,
    hasError,
    errorsFor,
  };
});
