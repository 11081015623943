export const useGetAddressStore = defineStore("getAddress", () => {
    const storeAddresses = ref();
    const storeAddress = ref();

    function addGetAddressAddresses(addresses: Array<any>): void {
        storeAddresses.value = addresses;
    }

    function addGetAddressAddress(address: any): void {
        storeAddress.value = address;
    }

    function clearAddresses(): void {
        storeAddresses.value = [];
    }

    async function autoCompleteAddresses(
        text: string,
    ): Promise<Ref> {
        const { error, data } = await useFetch(
            useJustHireUrl(
                `/api/get-address/autocomplete/${text}`
            ),
            {
                headers: useJustHireHeaders(),
            }
        );

        if (error.value) {
            useHandleJHError(error);
        }

        storeAddresses.value = data.value?.data.suggestions;

        return data.value?.data.suggestions;
    }

    async function findAddress(
        addressId: any
    ): Promise<Array<any>> {
        const { error, data } = await useFetch(
            useJustHireUrl(`/api/get-address/find/${addressId}`),
            {
                headers: useJustHireHeaders(),
            }
        );

        if (error.value) {
            useHandleJHError(error);
        }

        storeAddress.value = data.value?.data;

        return data.value?.data;
    }

    return {
        getAddressAddresses: storeAddresses,
        getAddressAddress: storeAddress,
        autoCompleteAddresses,
        findAddress,
    };
});
