import { Order } from "~~/types/Order";

export default function useAddToCartGa(
  booking: Order,
  equipmentTypeId: number
): void {
  let itemType = null;
  let price = 0;

  booking.equipment.forEach((equipmentItem) => {
    if (equipmentItem.type.id == equipmentTypeId) {
      itemType = equipmentItem.type;
      price = equipmentItem.price;
    }
  });

  if (!itemType) {
    return;
  }

  if (!process.client) {
    return;
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: "add_to_cart",
    ecommerce: {
      items: [
        {
          item_id: itemType.id,
          item_name: itemType.name,
          currency: "GBP",
          price: price,
          quantity: 1,
        },
      ],
    },
  });
}
