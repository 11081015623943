import { default as _91_46_46_46slug_93EhWyQvnGPmMeta } from "/opt/build/repo/pages/[...slug].vue?macro=true";
import { default as indexzjaNqSEaloMeta } from "/opt/build/repo/pages/booking/add-on/index.vue?macro=true";
import { default as _91attributeUuid_93JkdyEfddUBMeta } from "/opt/build/repo/pages/booking/attribute/[attributeUuid].vue?macro=true";
import { default as basketuAF7jOR4yUMeta } from "/opt/build/repo/pages/booking/basket.vue?macro=true";
import { default as _91_91categoryUuid_93_93IFKN4S7EudMeta } from "/opt/build/repo/pages/booking/category/[[categoryUuid]].vue?macro=true";
import { default as checkoutZdnSZSWP2SMeta } from "/opt/build/repo/pages/booking/checkout.vue?macro=true";
import { default as confirmationF4sNVQClRwMeta } from "/opt/build/repo/pages/booking/confirmation.vue?macro=true";
import { default as end_45dateQDp4Nm2NzXMeta } from "/opt/build/repo/pages/booking/end-date.vue?macro=true";
import { default as _91categoryUuid_93TsOfBrEKmkMeta } from "/opt/build/repo/pages/booking/equipment-type/[categoryUuid].vue?macro=true";
import { default as locationSRRwl3dT3aMeta } from "/opt/build/repo/pages/booking/location.vue?macro=true";
import { default as handlerVKuyxUG6vZMeta } from "/opt/build/repo/pages/booking/payment/handler.vue?macro=true";
import { default as indexT5luUSProvMeta } from "/opt/build/repo/pages/booking/payment/index.vue?macro=true";
import { default as _91orderReference_93ANNDcum6L0Meta } from "/opt/build/repo/pages/booking/regenerate/[orderReference].vue?macro=true";
import { default as start_45datewkjdeSTTbGMeta } from "/opt/build/repo/pages/booking/start-date.vue?macro=true";
import { default as contact_45usM8CpKpYLVzMeta } from "/opt/build/repo/pages/contact-us.vue?macro=true";
import { default as _91equipmentTypeSlug_93YSNFqb7rtSMeta } from "/opt/build/repo/pages/equipment/[equipmentTypeSlug].vue?macro=true";
import { default as _91attributeValueSlug_934MubDlmLlUMeta } from "/opt/build/repo/pages/equipment/attributes/[categorySlug]/[attributeSlug]/[attributeValueSlug].vue?macro=true";
import { default as _91_46_46_46categorySlug_93cYrRQsjETLMeta } from "/opt/build/repo/pages/equipment/categories/[...categorySlug].vue?macro=true";
import { default as indexlaFeZuYZkUMeta } from "/opt/build/repo/pages/index.vue?macro=true";
import { default as _91articleSlug_93abgr3m7ko9Meta } from "/opt/build/repo/pages/knowledge/[articleSlug].vue?macro=true";
import { default as indexe4gVSv8YqFMeta } from "/opt/build/repo/pages/knowledge/index.vue?macro=true";
import { default as _91townCode_93mt9p9VViD5Meta } from "/opt/build/repo/pages/locations/category/[categorySlug]/[countyCode]/[townCode].vue?macro=true";
import { default as index8qpJy8byvzMeta } from "/opt/build/repo/pages/locations/category/[categorySlug]/[countyCode]/index.vue?macro=true";
import { default as my_45bookinghptbBivJSvMeta } from "/opt/build/repo/pages/my-booking.vue?macro=true";
import { default as _91_46_46_46slug_93JqrI17zkodMeta } from "/opt/build/repo/pages/pl/[...slug].vue?macro=true";
import { default as _91email_93gYDxd8wzsUMeta } from "/opt/build/repo/pages/supplier/manage-booking/[orderReference]/[email].vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_93EhWyQvnGPmMeta?.name ?? "slug",
    path: _91_46_46_46slug_93EhWyQvnGPmMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_93EhWyQvnGPmMeta || {},
    alias: _91_46_46_46slug_93EhWyQvnGPmMeta?.alias || [],
    redirect: _91_46_46_46slug_93EhWyQvnGPmMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: indexzjaNqSEaloMeta?.name ?? "booking-add-on",
    path: indexzjaNqSEaloMeta?.path ?? "/booking/add-on",
    meta: indexzjaNqSEaloMeta || {},
    alias: indexzjaNqSEaloMeta?.alias || [],
    redirect: indexzjaNqSEaloMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/booking/add-on/index.vue").then(m => m.default || m)
  },
  {
    name: _91attributeUuid_93JkdyEfddUBMeta?.name ?? "booking-attribute-attributeUuid",
    path: _91attributeUuid_93JkdyEfddUBMeta?.path ?? "/booking/attribute/:attributeUuid()",
    meta: _91attributeUuid_93JkdyEfddUBMeta || {},
    alias: _91attributeUuid_93JkdyEfddUBMeta?.alias || [],
    redirect: _91attributeUuid_93JkdyEfddUBMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/booking/attribute/[attributeUuid].vue").then(m => m.default || m)
  },
  {
    name: basketuAF7jOR4yUMeta?.name ?? "booking-basket",
    path: basketuAF7jOR4yUMeta?.path ?? "/booking/basket",
    meta: basketuAF7jOR4yUMeta || {},
    alias: basketuAF7jOR4yUMeta?.alias || [],
    redirect: basketuAF7jOR4yUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/booking/basket.vue").then(m => m.default || m)
  },
  {
    name: _91_91categoryUuid_93_93IFKN4S7EudMeta?.name ?? "booking-category-categoryUuid",
    path: _91_91categoryUuid_93_93IFKN4S7EudMeta?.path ?? "/booking/category/:categoryUuid?",
    meta: _91_91categoryUuid_93_93IFKN4S7EudMeta || {},
    alias: _91_91categoryUuid_93_93IFKN4S7EudMeta?.alias || [],
    redirect: _91_91categoryUuid_93_93IFKN4S7EudMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/booking/category/[[categoryUuid]].vue").then(m => m.default || m)
  },
  {
    name: checkoutZdnSZSWP2SMeta?.name ?? "booking-checkout",
    path: checkoutZdnSZSWP2SMeta?.path ?? "/booking/checkout",
    meta: checkoutZdnSZSWP2SMeta || {},
    alias: checkoutZdnSZSWP2SMeta?.alias || [],
    redirect: checkoutZdnSZSWP2SMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/booking/checkout.vue").then(m => m.default || m)
  },
  {
    name: confirmationF4sNVQClRwMeta?.name ?? "booking-confirmation",
    path: confirmationF4sNVQClRwMeta?.path ?? "/booking/confirmation",
    meta: confirmationF4sNVQClRwMeta || {},
    alias: confirmationF4sNVQClRwMeta?.alias || [],
    redirect: confirmationF4sNVQClRwMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/booking/confirmation.vue").then(m => m.default || m)
  },
  {
    name: end_45dateQDp4Nm2NzXMeta?.name ?? "booking-end-date",
    path: end_45dateQDp4Nm2NzXMeta?.path ?? "/booking/end-date",
    meta: end_45dateQDp4Nm2NzXMeta || {},
    alias: end_45dateQDp4Nm2NzXMeta?.alias || [],
    redirect: end_45dateQDp4Nm2NzXMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/booking/end-date.vue").then(m => m.default || m)
  },
  {
    name: _91categoryUuid_93TsOfBrEKmkMeta?.name ?? "booking-equipment-type-categoryUuid",
    path: _91categoryUuid_93TsOfBrEKmkMeta?.path ?? "/booking/equipment-type/:categoryUuid()",
    meta: _91categoryUuid_93TsOfBrEKmkMeta || {},
    alias: _91categoryUuid_93TsOfBrEKmkMeta?.alias || [],
    redirect: _91categoryUuid_93TsOfBrEKmkMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/booking/equipment-type/[categoryUuid].vue").then(m => m.default || m)
  },
  {
    name: locationSRRwl3dT3aMeta?.name ?? "booking-location",
    path: locationSRRwl3dT3aMeta?.path ?? "/booking/location",
    meta: locationSRRwl3dT3aMeta || {},
    alias: locationSRRwl3dT3aMeta?.alias || [],
    redirect: locationSRRwl3dT3aMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/booking/location.vue").then(m => m.default || m)
  },
  {
    name: handlerVKuyxUG6vZMeta?.name ?? "booking-payment-handler",
    path: handlerVKuyxUG6vZMeta?.path ?? "/booking/payment/handler",
    meta: handlerVKuyxUG6vZMeta || {},
    alias: handlerVKuyxUG6vZMeta?.alias || [],
    redirect: handlerVKuyxUG6vZMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/booking/payment/handler.vue").then(m => m.default || m)
  },
  {
    name: indexT5luUSProvMeta?.name ?? "booking-payment",
    path: indexT5luUSProvMeta?.path ?? "/booking/payment",
    meta: indexT5luUSProvMeta || {},
    alias: indexT5luUSProvMeta?.alias || [],
    redirect: indexT5luUSProvMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/booking/payment/index.vue").then(m => m.default || m)
  },
  {
    name: _91orderReference_93ANNDcum6L0Meta?.name ?? "booking-regenerate-orderReference",
    path: _91orderReference_93ANNDcum6L0Meta?.path ?? "/booking/regenerate/:orderReference()",
    meta: _91orderReference_93ANNDcum6L0Meta || {},
    alias: _91orderReference_93ANNDcum6L0Meta?.alias || [],
    redirect: _91orderReference_93ANNDcum6L0Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/booking/regenerate/[orderReference].vue").then(m => m.default || m)
  },
  {
    name: start_45datewkjdeSTTbGMeta?.name ?? "booking-start-date",
    path: start_45datewkjdeSTTbGMeta?.path ?? "/booking/start-date",
    meta: start_45datewkjdeSTTbGMeta || {},
    alias: start_45datewkjdeSTTbGMeta?.alias || [],
    redirect: start_45datewkjdeSTTbGMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/booking/start-date.vue").then(m => m.default || m)
  },
  {
    name: contact_45usM8CpKpYLVzMeta?.name ?? "contact-us",
    path: contact_45usM8CpKpYLVzMeta?.path ?? "/contact-us",
    meta: contact_45usM8CpKpYLVzMeta || {},
    alias: contact_45usM8CpKpYLVzMeta?.alias || [],
    redirect: contact_45usM8CpKpYLVzMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/contact-us.vue").then(m => m.default || m)
  },
  {
    name: _91equipmentTypeSlug_93YSNFqb7rtSMeta?.name ?? "equipment-equipmentTypeSlug",
    path: _91equipmentTypeSlug_93YSNFqb7rtSMeta?.path ?? "/equipment/:equipmentTypeSlug()",
    meta: _91equipmentTypeSlug_93YSNFqb7rtSMeta || {},
    alias: _91equipmentTypeSlug_93YSNFqb7rtSMeta?.alias || [],
    redirect: _91equipmentTypeSlug_93YSNFqb7rtSMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/equipment/[equipmentTypeSlug].vue").then(m => m.default || m)
  },
  {
    name: _91attributeValueSlug_934MubDlmLlUMeta?.name ?? "equipment-attributes-categorySlug-attributeSlug-attributeValueSlug",
    path: _91attributeValueSlug_934MubDlmLlUMeta?.path ?? "/equipment/attributes/:categorySlug()/:attributeSlug()/:attributeValueSlug()",
    meta: _91attributeValueSlug_934MubDlmLlUMeta || {},
    alias: _91attributeValueSlug_934MubDlmLlUMeta?.alias || [],
    redirect: _91attributeValueSlug_934MubDlmLlUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/equipment/attributes/[categorySlug]/[attributeSlug]/[attributeValueSlug].vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46categorySlug_93cYrRQsjETLMeta?.name ?? "equipment-categories-categorySlug",
    path: _91_46_46_46categorySlug_93cYrRQsjETLMeta?.path ?? "/equipment/categories/:categorySlug(.*)*",
    meta: _91_46_46_46categorySlug_93cYrRQsjETLMeta || {},
    alias: _91_46_46_46categorySlug_93cYrRQsjETLMeta?.alias || [],
    redirect: _91_46_46_46categorySlug_93cYrRQsjETLMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/equipment/categories/[...categorySlug].vue").then(m => m.default || m)
  },
  {
    name: indexlaFeZuYZkUMeta?.name ?? "index",
    path: indexlaFeZuYZkUMeta?.path ?? "/",
    meta: indexlaFeZuYZkUMeta || {},
    alias: indexlaFeZuYZkUMeta?.alias || [],
    redirect: indexlaFeZuYZkUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91articleSlug_93abgr3m7ko9Meta?.name ?? "knowledge-articleSlug",
    path: _91articleSlug_93abgr3m7ko9Meta?.path ?? "/knowledge/:articleSlug()",
    meta: _91articleSlug_93abgr3m7ko9Meta || {},
    alias: _91articleSlug_93abgr3m7ko9Meta?.alias || [],
    redirect: _91articleSlug_93abgr3m7ko9Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/knowledge/[articleSlug].vue").then(m => m.default || m)
  },
  {
    name: indexe4gVSv8YqFMeta?.name ?? "knowledge",
    path: indexe4gVSv8YqFMeta?.path ?? "/knowledge",
    meta: indexe4gVSv8YqFMeta || {},
    alias: indexe4gVSv8YqFMeta?.alias || [],
    redirect: indexe4gVSv8YqFMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/knowledge/index.vue").then(m => m.default || m)
  },
  {
    name: _91townCode_93mt9p9VViD5Meta?.name ?? "locations-category-categorySlug-countyCode-townCode",
    path: _91townCode_93mt9p9VViD5Meta?.path ?? "/locations/category/:categorySlug()/:countyCode()/:townCode()",
    meta: _91townCode_93mt9p9VViD5Meta || {},
    alias: _91townCode_93mt9p9VViD5Meta?.alias || [],
    redirect: _91townCode_93mt9p9VViD5Meta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/locations/category/[categorySlug]/[countyCode]/[townCode].vue").then(m => m.default || m)
  },
  {
    name: index8qpJy8byvzMeta?.name ?? "locations-category-categorySlug-countyCode",
    path: index8qpJy8byvzMeta?.path ?? "/locations/category/:categorySlug()/:countyCode()",
    meta: index8qpJy8byvzMeta || {},
    alias: index8qpJy8byvzMeta?.alias || [],
    redirect: index8qpJy8byvzMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/locations/category/[categorySlug]/[countyCode]/index.vue").then(m => m.default || m)
  },
  {
    name: my_45bookinghptbBivJSvMeta?.name ?? "my-booking",
    path: my_45bookinghptbBivJSvMeta?.path ?? "/my-booking",
    meta: my_45bookinghptbBivJSvMeta || {},
    alias: my_45bookinghptbBivJSvMeta?.alias || [],
    redirect: my_45bookinghptbBivJSvMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/my-booking.vue").then(m => m.default || m)
  },
  {
    name: _91_46_46_46slug_93JqrI17zkodMeta?.name ?? "pl-slug",
    path: _91_46_46_46slug_93JqrI17zkodMeta?.path ?? "/pl/:slug(.*)*",
    meta: _91_46_46_46slug_93JqrI17zkodMeta || {},
    alias: _91_46_46_46slug_93JqrI17zkodMeta?.alias || [],
    redirect: _91_46_46_46slug_93JqrI17zkodMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/pl/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _91email_93gYDxd8wzsUMeta?.name ?? "supplier-manage-booking-orderReference-email",
    path: _91email_93gYDxd8wzsUMeta?.path ?? "/supplier/manage-booking/:orderReference()/:email()",
    meta: _91email_93gYDxd8wzsUMeta || {},
    alias: _91email_93gYDxd8wzsUMeta?.alias || [],
    redirect: _91email_93gYDxd8wzsUMeta?.redirect || undefined,
    component: () => import("/opt/build/repo/pages/supplier/manage-booking/[orderReference]/[email].vue").then(m => m.default || m)
  }
]