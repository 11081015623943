export enum BookingPage {
  Location = "location",
  Category = "category",
  Attribute = "attribute",
  EquipmentType = "equipment-type",
  AddOn = "add-on",
  Basket = "basket",
  StartDate = "start-date",
  EndDate = "end-date",
  Checkout = "checkout",
  Payment = "payment",
  Confirmation = "confirmation",
}
