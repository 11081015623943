import { Order } from "~~/types/Order";

export default function usePurchaseGa(booking: Order): void {
  let reference = booking.reference;
  let equipment = booking.equipment;
  let items = [];

  equipment.forEach((item) => {
    items.push({
      item_id: item.type.id,
      item_name: item.type.name,
      currency: "GBP",
      price: item.price,
      quantity: 1,
    });
  });

  if (!process.client) {
    return;
  }

  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push({ ecommerce: null });
  window.dataLayer.push({
    event: "purchase",
    ecommerce: {
      transaction_id: reference,
      value: booking.totalPrice,
      tax: 0,
      shipping: 0,
      currency: "GBP",
      coupon: "",
      items: items,
    },
  });
}
