export default function useHandleJHError(error: Ref): string {
  // const { setErrors } = useErrorStore();

  // setErrors(error.value?.response?._data);

  // console.log(error.value._data);

  throw createError({
    statusCode: error.value?.statusCode,
    ...error.value?.data,
  });
}
