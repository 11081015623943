import { defineAsyncComponent } from 'vue'
import { createClientOnly } from "#app/components/client-only";
import { default as __nuxt_component_1 } from "/opt/build/repo/components/trustpilot/MicroReview.client.vue";
export { default as BookingBody } from "/opt/build/repo/components/booking/Body.vue";
export const LazyBookingBody = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/components/booking/Body.vue" /* webpackChunkName: "components/booking-body" */).then(c => c.default || c))
export { default as BookingDateItem } from "/opt/build/repo/components/booking/DateItem.vue";
export const LazyBookingDateItem = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/components/booking/DateItem.vue" /* webpackChunkName: "components/booking-date-item" */).then(c => c.default || c))
export { default as BookingDates } from "/opt/build/repo/components/booking/Dates.vue";
export const LazyBookingDates = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/components/booking/Dates.vue" /* webpackChunkName: "components/booking-dates" */).then(c => c.default || c))
export { default as BookingFooterControls } from "/opt/build/repo/components/booking/FooterControls.vue";
export const LazyBookingFooterControls = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/components/booking/FooterControls.vue" /* webpackChunkName: "components/booking-footer-controls" */).then(c => c.default || c))
export { default as BookingHeader } from "/opt/build/repo/components/booking/Header.vue";
export const LazyBookingHeader = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/components/booking/Header.vue" /* webpackChunkName: "components/booking-header" */).then(c => c.default || c))
export { default as BookingNavigation } from "/opt/build/repo/components/booking/Navigation.vue";
export const LazyBookingNavigation = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/components/booking/Navigation.vue" /* webpackChunkName: "components/booking-navigation" */).then(c => c.default || c))
export { default as BookingNavigationLink } from "/opt/build/repo/components/booking/NavigationLink.vue";
export const LazyBookingNavigationLink = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/components/booking/NavigationLink.vue" /* webpackChunkName: "components/booking-navigation-link" */).then(c => c.default || c))
export { default as BookingPostcode } from "/opt/build/repo/components/booking/Postcode.vue";
export const LazyBookingPostcode = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/components/booking/Postcode.vue" /* webpackChunkName: "components/booking-postcode" */).then(c => c.default || c))
export { default as BookingSummary } from "/opt/build/repo/components/booking/Summary.vue";
export const LazyBookingSummary = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/components/booking/Summary.vue" /* webpackChunkName: "components/booking-summary" */).then(c => c.default || c))
export { default as BookingAddOnItem } from "/opt/build/repo/components/booking/addOn/Item.vue";
export const LazyBookingAddOnItem = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/components/booking/addOn/Item.vue" /* webpackChunkName: "components/booking-add-on-item" */).then(c => c.default || c))
export { default as BookingAttributeValueItem } from "/opt/build/repo/components/booking/attribute/value/Item.vue";
export const LazyBookingAttributeValueItem = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/components/booking/attribute/value/Item.vue" /* webpackChunkName: "components/booking-attribute-value-item" */).then(c => c.default || c))
export { default as BookingBasket } from "/opt/build/repo/components/booking/basket/Index.vue";
export const LazyBookingBasket = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/components/booking/basket/Index.vue" /* webpackChunkName: "components/booking-basket" */).then(c => c.default || c))
export { default as BookingBasketItem } from "/opt/build/repo/components/booking/basket/Item.vue";
export const LazyBookingBasketItem = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/components/booking/basket/Item.vue" /* webpackChunkName: "components/booking-basket-item" */).then(c => c.default || c))
export { default as BookingCategoryItem } from "/opt/build/repo/components/booking/category/Item.vue";
export const LazyBookingCategoryItem = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/components/booking/category/Item.vue" /* webpackChunkName: "components/booking-category-item" */).then(c => c.default || c))
export { default as BookingCheckoutAddressSelect } from "/opt/build/repo/components/booking/checkout/AddressSelect.vue";
export const LazyBookingCheckoutAddressSelect = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/components/booking/checkout/AddressSelect.vue" /* webpackChunkName: "components/booking-checkout-address-select" */).then(c => c.default || c))
export { default as BookingCheckoutForm } from "/opt/build/repo/components/booking/checkout/Form.vue";
export const LazyBookingCheckoutForm = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/components/booking/checkout/Form.vue" /* webpackChunkName: "components/booking-checkout-form" */).then(c => c.default || c))
export { default as BookingCheckoutInput } from "/opt/build/repo/components/booking/checkout/Input.vue";
export const LazyBookingCheckoutInput = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/components/booking/checkout/Input.vue" /* webpackChunkName: "components/booking-checkout-input" */).then(c => c.default || c))
export { default as BookingEquipmentTypeItem } from "/opt/build/repo/components/booking/equipmentType/Item.vue";
export const LazyBookingEquipmentTypeItem = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/components/booking/equipmentType/Item.vue" /* webpackChunkName: "components/booking-equipment-type-item" */).then(c => c.default || c))
export { default as BookingPaymentForm } from "/opt/build/repo/components/booking/payment/Form.vue";
export const LazyBookingPaymentForm = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/components/booking/payment/Form.vue" /* webpackChunkName: "components/booking-payment-form" */).then(c => c.default || c))
export { default as BookingPaymentMethods } from "/opt/build/repo/components/booking/payment/Methods.vue";
export const LazyBookingPaymentMethods = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/components/booking/payment/Methods.vue" /* webpackChunkName: "components/booking-payment-methods" */).then(c => c.default || c))
export { default as ContactForm } from "/opt/build/repo/components/contact/Form.vue";
export const LazyContactForm = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/components/contact/Form.vue" /* webpackChunkName: "components/contact-form" */).then(c => c.default || c))
export { default as GenericAvailableSkips } from "/opt/build/repo/components/generic/AvailableSkips.vue";
export const LazyGenericAvailableSkips = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/components/generic/AvailableSkips.vue" /* webpackChunkName: "components/generic-available-skips" */).then(c => c.default || c))
export { default as GenericBookOnline } from "/opt/build/repo/components/generic/BookOnline.vue";
export const LazyGenericBookOnline = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/components/generic/BookOnline.vue" /* webpackChunkName: "components/generic-book-online" */).then(c => c.default || c))
export { default as GenericBreadcrumbs } from "/opt/build/repo/components/generic/Breadcrumbs.vue";
export const LazyGenericBreadcrumbs = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/components/generic/Breadcrumbs.vue" /* webpackChunkName: "components/generic-breadcrumbs" */).then(c => c.default || c))
export { default as GenericFaqs } from "/opt/build/repo/components/generic/Faqs.vue";
export const LazyGenericFaqs = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/components/generic/Faqs.vue" /* webpackChunkName: "components/generic-faqs" */).then(c => c.default || c))
export { default as GenericLocations } from "/opt/build/repo/components/generic/Locations.vue";
export const LazyGenericLocations = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/components/generic/Locations.vue" /* webpackChunkName: "components/generic-locations" */).then(c => c.default || c))
export { default as GenericMap } from "/opt/build/repo/components/generic/Map.vue";
export const LazyGenericMap = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/components/generic/Map.vue" /* webpackChunkName: "components/generic-map" */).then(c => c.default || c))
export { default as GenericSkipHero } from "/opt/build/repo/components/generic/SkipHero.vue";
export const LazyGenericSkipHero = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/components/generic/SkipHero.vue" /* webpackChunkName: "components/generic-skip-hero" */).then(c => c.default || c))
export { default as GenericSkipPage } from "/opt/build/repo/components/generic/SkipPage.vue";
export const LazyGenericSkipPage = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/components/generic/SkipPage.vue" /* webpackChunkName: "components/generic-skip-page" */).then(c => c.default || c))
export { default as GenericSkipPageMap } from "/opt/build/repo/components/generic/SkipPageMap.vue";
export const LazyGenericSkipPageMap = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/components/generic/SkipPageMap.vue" /* webpackChunkName: "components/generic-skip-page-map" */).then(c => c.default || c))
export { default as GenericSkipTypes } from "/opt/build/repo/components/generic/SkipTypes.vue";
export const LazyGenericSkipTypes = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/components/generic/SkipTypes.vue" /* webpackChunkName: "components/generic-skip-types" */).then(c => c.default || c))
export { default as GenericWastePage } from "/opt/build/repo/components/generic/WastePage.vue";
export const LazyGenericWastePage = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/components/generic/WastePage.vue" /* webpackChunkName: "components/generic-waste-page" */).then(c => c.default || c))
export { default as HomeHero } from "/opt/build/repo/components/home/Hero.vue";
export const LazyHomeHero = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/components/home/Hero.vue" /* webpackChunkName: "components/home-hero" */).then(c => c.default || c))
export { default as HomeTimeline } from "/opt/build/repo/components/home/Timeline.vue";
export const LazyHomeTimeline = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/components/home/Timeline.vue" /* webpackChunkName: "components/home-timeline" */).then(c => c.default || c))
export { default as HomeWorker } from "/opt/build/repo/components/home/Worker.vue";
export const LazyHomeWorker = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/components/home/Worker.vue" /* webpackChunkName: "components/home-worker" */).then(c => c.default || c))
export { default as KnowledgeArticleCard } from "/opt/build/repo/components/knowledge/ArticleCard.vue";
export const LazyKnowledgeArticleCard = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/components/knowledge/ArticleCard.vue" /* webpackChunkName: "components/knowledge-article-card" */).then(c => c.default || c))
export { default as KnowledgeHelpfulGuides } from "/opt/build/repo/components/knowledge/HelpfulGuides.vue";
export const LazyKnowledgeHelpfulGuides = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/components/knowledge/HelpfulGuides.vue" /* webpackChunkName: "components/knowledge-helpful-guides" */).then(c => c.default || c))
export { default as KnowledgeSearchArticles } from "/opt/build/repo/components/knowledge/SearchArticles.vue";
export const LazyKnowledgeSearchArticles = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/components/knowledge/SearchArticles.vue" /* webpackChunkName: "components/knowledge-search-articles" */).then(c => c.default || c))
export { default as LayoutFooter } from "/opt/build/repo/components/layout/Footer.vue";
export const LazyLayoutFooter = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/components/layout/Footer.vue" /* webpackChunkName: "components/layout-footer" */).then(c => c.default || c))
export { default as LayoutNavbar } from "/opt/build/repo/components/layout/Navbar.vue";
export const LazyLayoutNavbar = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/components/layout/Navbar.vue" /* webpackChunkName: "components/layout-navbar" */).then(c => c.default || c))
export { default as ModalsGeneric } from "/opt/build/repo/components/modals/Generic.vue";
export const LazyModalsGeneric = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/components/modals/Generic.vue" /* webpackChunkName: "components/modals-generic" */).then(c => c.default || c))
export const TrustpilotMicroReview = /* #__PURE__ */ createClientOnly(__nuxt_component_1)
export const LazyTrustpilotMicroReview = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/components/trustpilot/MicroReview.client.vue" /* webpackChunkName: "components/trustpilot-micro-review-client" */).then(c => createClientOnly(c.default || c)))
export { default as BlueButton } from "/opt/build/repo/storyblok/Buttons/BlueButton.vue";
export const LazyBlueButton = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/storyblok/Buttons/BlueButton.vue" /* webpackChunkName: "components/blue-button" */).then(c => c.default || c))
export { default as Divider } from "/opt/build/repo/storyblok/Divider.vue";
export const LazyDivider = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/storyblok/Divider.vue" /* webpackChunkName: "components/divider" */).then(c => c.default || c))
export { default as IncentivesFourColumnIcons } from "/opt/build/repo/storyblok/Ecommerce/IncentivesFourColumnIcons.vue";
export const LazyIncentivesFourColumnIcons = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/storyblok/Ecommerce/IncentivesFourColumnIcons.vue" /* webpackChunkName: "components/incentives-four-column-icons" */).then(c => c.default || c))
export { default as Faq } from "/opt/build/repo/storyblok/Faqs/Faq.vue";
export const LazyFaq = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/storyblok/Faqs/Faq.vue" /* webpackChunkName: "components/faq" */).then(c => c.default || c))
export { default as Stacked } from "/opt/build/repo/storyblok/Faqs/Stacked.vue";
export const LazyStacked = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/storyblok/Faqs/Stacked.vue" /* webpackChunkName: "components/stacked" */).then(c => c.default || c))
export { default as Feature } from "/opt/build/repo/storyblok/Feature.vue";
export const LazyFeature = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/storyblok/Feature.vue" /* webpackChunkName: "components/feature" */).then(c => c.default || c))
export { default as FullImage } from "/opt/build/repo/storyblok/FullImage.vue";
export const LazyFullImage = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/storyblok/FullImage.vue" /* webpackChunkName: "components/full-image" */).then(c => c.default || c))
export { default as FullRichText } from "/opt/build/repo/storyblok/FullRichText.vue";
export const LazyFullRichText = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/storyblok/FullRichText.vue" /* webpackChunkName: "components/full-rich-text" */).then(c => c.default || c))
export { default as BlankSpace } from "/opt/build/repo/storyblok/Generic/BlankSpace.vue";
export const LazyBlankSpace = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/storyblok/Generic/BlankSpace.vue" /* webpackChunkName: "components/blank-space" */).then(c => c.default || c))
export { default as BlueWorkerBanner } from "/opt/build/repo/storyblok/Generic/BlueWorkerBanner.vue";
export const LazyBlueWorkerBanner = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/storyblok/Generic/BlueWorkerBanner.vue" /* webpackChunkName: "components/blue-worker-banner" */).then(c => c.default || c))
export { default as RichText } from "/opt/build/repo/storyblok/Generic/RichText.vue";
export const LazyRichText = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/storyblok/Generic/RichText.vue" /* webpackChunkName: "components/rich-text" */).then(c => c.default || c))
export { default as Grid } from "/opt/build/repo/storyblok/Grid.vue";
export const LazyGrid = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/storyblok/Grid.vue" /* webpackChunkName: "components/grid" */).then(c => c.default || c))
export { default as Home } from "/opt/build/repo/storyblok/Heros/Home.vue";
export const LazyHome = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/storyblok/Heros/Home.vue" /* webpackChunkName: "components/home" */).then(c => c.default || c))
export { default as RoundedImageText } from "/opt/build/repo/storyblok/Heros/RoundedImageText.vue";
export const LazyRoundedImageText = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/storyblok/Heros/RoundedImageText.vue" /* webpackChunkName: "components/rounded-image-text" */).then(c => c.default || c))
export { default as TextRoundedImage } from "/opt/build/repo/storyblok/Heros/TextRoundedImage.vue";
export const LazyTextRoundedImage = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/storyblok/Heros/TextRoundedImage.vue" /* webpackChunkName: "components/text-rounded-image" */).then(c => c.default || c))
export { default as GridList } from "/opt/build/repo/storyblok/Lists/GridList.vue";
export const LazyGridList = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/storyblok/Lists/GridList.vue" /* webpackChunkName: "components/grid-list" */).then(c => c.default || c))
export { default as ListItem } from "/opt/build/repo/storyblok/Lists/ListItem.vue";
export const LazyListItem = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/storyblok/Lists/ListItem.vue" /* webpackChunkName: "components/list-item" */).then(c => c.default || c))
export { default as Page } from "/opt/build/repo/storyblok/Page.vue";
export const LazyPage = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/storyblok/Page.vue" /* webpackChunkName: "components/page" */).then(c => c.default || c))
export { default as OurStory } from "/opt/build/repo/storyblok/Pages/About/OurStory.vue";
export const LazyOurStory = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/storyblok/Pages/About/OurStory.vue" /* webpackChunkName: "components/our-story" */).then(c => c.default || c))
export { default as Cards } from "/opt/build/repo/storyblok/Product/Cards.vue";
export const LazyCards = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/storyblok/Product/Cards.vue" /* webpackChunkName: "components/cards" */).then(c => c.default || c))
export { default as HeaderSectionCentered } from "/opt/build/repo/storyblok/Sections/HeaderSectionCentered.vue";
export const LazyHeaderSectionCentered = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/storyblok/Sections/HeaderSectionCentered.vue" /* webpackChunkName: "components/header-section-centered" */).then(c => c.default || c))
export { default as TextImageLeft } from "/opt/build/repo/storyblok/Sections/TextImageLeft.vue";
export const LazyTextImageLeft = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/storyblok/Sections/TextImageLeft.vue" /* webpackChunkName: "components/text-image-left" */).then(c => c.default || c))
export { default as TextImageRight } from "/opt/build/repo/storyblok/Sections/TextImageRight.vue";
export const LazyTextImageRight = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/storyblok/Sections/TextImageRight.vue" /* webpackChunkName: "components/text-image-right" */).then(c => c.default || c))
export { default as ThreeUsps } from "/opt/build/repo/storyblok/Sections/ThreeUsps.vue";
export const LazyThreeUsps = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/storyblok/Sections/ThreeUsps.vue" /* webpackChunkName: "components/three-usps" */).then(c => c.default || c))
export { default as VerticalUsps } from "/opt/build/repo/storyblok/Sections/VerticalUsps.vue";
export const LazyVerticalUsps = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/storyblok/Sections/VerticalUsps.vue" /* webpackChunkName: "components/vertical-usps" */).then(c => c.default || c))
export { default as SkipHireNearMeSizes } from "/opt/build/repo/storyblok/SkipHire/SkipHireNearMeSizes.vue";
export const LazySkipHireNearMeSizes = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/storyblok/SkipHire/SkipHireNearMeSizes.vue" /* webpackChunkName: "components/skip-hire-near-me-sizes" */).then(c => c.default || c))
export { default as WasteIcon } from "/opt/build/repo/storyblok/SkipHire/WasteIcon.vue";
export const LazyWasteIcon = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/storyblok/SkipHire/WasteIcon.vue" /* webpackChunkName: "components/waste-icon" */).then(c => c.default || c))
export { default as WasteTypes } from "/opt/build/repo/storyblok/SkipHire/WasteTypes.vue";
export const LazyWasteTypes = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/storyblok/SkipHire/WasteTypes.vue" /* webpackChunkName: "components/waste-types" */).then(c => c.default || c))
export { default as HowItWorks } from "/opt/build/repo/storyblok/Timelines/HowItWorks.vue";
export const LazyHowItWorks = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/storyblok/Timelines/HowItWorks.vue" /* webpackChunkName: "components/how-it-works" */).then(c => c.default || c))
export { default as MicroReview } from "/opt/build/repo/storyblok/Trustpilot/MicroReview.vue";
export const LazyMicroReview = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/storyblok/Trustpilot/MicroReview.vue" /* webpackChunkName: "components/micro-review" */).then(c => c.default || c))
export { default as NuxtWelcome } from "/opt/build/repo/node_modules/@nuxt/ui-templates/dist/templates/welcome.vue";
export const LazyNuxtWelcome = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/node_modules/@nuxt/ui-templates/dist/templates/welcome.vue" /* webpackChunkName: "components/nuxt-welcome" */).then(c => c.default || c))
export { default as NuxtLayout } from "/opt/build/repo/node_modules/nuxt/dist/app/components/layout";
export const LazyNuxtLayout = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/node_modules/nuxt/dist/app/components/layout" /* webpackChunkName: "components/nuxt-layout" */).then(c => c.default || c))
export { default as NuxtErrorBoundary } from "/opt/build/repo/node_modules/nuxt/dist/app/components/nuxt-error-boundary";
export const LazyNuxtErrorBoundary = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/node_modules/nuxt/dist/app/components/nuxt-error-boundary" /* webpackChunkName: "components/nuxt-error-boundary" */).then(c => c.default || c))
export { default as ClientOnly } from "/opt/build/repo/node_modules/nuxt/dist/app/components/client-only";
export const LazyClientOnly = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/node_modules/nuxt/dist/app/components/client-only" /* webpackChunkName: "components/client-only" */).then(c => c.default || c))
export { default as DevOnly } from "/opt/build/repo/node_modules/nuxt/dist/app/components/dev-only";
export const LazyDevOnly = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/node_modules/nuxt/dist/app/components/dev-only" /* webpackChunkName: "components/dev-only" */).then(c => c.default || c))
export { default as ServerPlaceholder } from "/opt/build/repo/node_modules/nuxt/dist/app/components/server-placeholder";
export const LazyServerPlaceholder = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/node_modules/nuxt/dist/app/components/server-placeholder" /* webpackChunkName: "components/server-placeholder" */).then(c => c.default || c))
export { default as NuxtLink } from "/opt/build/repo/node_modules/nuxt/dist/app/components/nuxt-link";
export const LazyNuxtLink = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/node_modules/nuxt/dist/app/components/nuxt-link" /* webpackChunkName: "components/nuxt-link" */).then(c => c.default || c))
export { default as NuxtLoadingIndicator } from "/opt/build/repo/node_modules/nuxt/dist/app/components/nuxt-loading-indicator";
export const LazyNuxtLoadingIndicator = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/node_modules/nuxt/dist/app/components/nuxt-loading-indicator" /* webpackChunkName: "components/nuxt-loading-indicator" */).then(c => c.default || c))
export { default as NuxtImg } from "/opt/build/repo/node_modules/@nuxt/image-edge/dist/runtime/components/nuxt-img";
export const LazyNuxtImg = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/node_modules/@nuxt/image-edge/dist/runtime/components/nuxt-img" /* webpackChunkName: "components/nuxt-img" */).then(c => c.default || c))
export { default as NuxtPicture } from "/opt/build/repo/node_modules/@nuxt/image-edge/dist/runtime/components/nuxt-picture";
export const LazyNuxtPicture = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/node_modules/@nuxt/image-edge/dist/runtime/components/nuxt-picture" /* webpackChunkName: "components/nuxt-picture" */).then(c => c.default || c))
export { default as NuxtPage } from "/opt/build/repo/node_modules/nuxt/dist/pages/runtime/page";
export const LazyNuxtPage = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/node_modules/nuxt/dist/pages/runtime/page" /* webpackChunkName: "components/nuxt-page" */).then(c => c.default || c))
export { NoScript } from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/components";
export const LazyNoScript = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/node_modules/nuxt/dist/head/runtime/components" /* webpackChunkName: "components/no-script" */).then(c => c['NoScript']))
export { Link } from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/components";
export const LazyLink = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/node_modules/nuxt/dist/head/runtime/components" /* webpackChunkName: "components/link" */).then(c => c['Link']))
export { Base } from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/components";
export const LazyBase = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/node_modules/nuxt/dist/head/runtime/components" /* webpackChunkName: "components/base" */).then(c => c['Base']))
export { Title } from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/components";
export const LazyTitle = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/node_modules/nuxt/dist/head/runtime/components" /* webpackChunkName: "components/title" */).then(c => c['Title']))
export { Meta } from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/components";
export const LazyMeta = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/node_modules/nuxt/dist/head/runtime/components" /* webpackChunkName: "components/meta" */).then(c => c['Meta']))
export { Style } from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/components";
export const LazyStyle = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/node_modules/nuxt/dist/head/runtime/components" /* webpackChunkName: "components/style" */).then(c => c['Style']))
export { Head } from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/components";
export const LazyHead = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/node_modules/nuxt/dist/head/runtime/components" /* webpackChunkName: "components/head" */).then(c => c['Head']))
export { Html } from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/components";
export const LazyHtml = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/node_modules/nuxt/dist/head/runtime/components" /* webpackChunkName: "components/html" */).then(c => c['Html']))
export { Body } from "/opt/build/repo/node_modules/nuxt/dist/head/runtime/components";
export const LazyBody = /* #__PURE__ */ defineAsyncComponent(() => import("/opt/build/repo/node_modules/nuxt/dist/head/runtime/components" /* webpackChunkName: "components/body" */).then(c => c['Body']))
export const lazyGlobalComponents = {
  BlueButton: LazyBlueButton,
  Divider: LazyDivider,
  IncentivesFourColumnIcons: LazyIncentivesFourColumnIcons,
  Faq: LazyFaq,
  Stacked: LazyStacked,
  Feature: LazyFeature,
  FullImage: LazyFullImage,
  FullRichText: LazyFullRichText,
  BlankSpace: LazyBlankSpace,
  BlueWorkerBanner: LazyBlueWorkerBanner,
  RichText: LazyRichText,
  Grid: LazyGrid,
  Home: LazyHome,
  RoundedImageText: LazyRoundedImageText,
  TextRoundedImage: LazyTextRoundedImage,
  GridList: LazyGridList,
  ListItem: LazyListItem,
  Page: LazyPage,
  OurStory: LazyOurStory,
  Cards: LazyCards,
  HeaderSectionCentered: LazyHeaderSectionCentered,
  TextImageLeft: LazyTextImageLeft,
  TextImageRight: LazyTextImageRight,
  ThreeUsps: LazyThreeUsps,
  VerticalUsps: LazyVerticalUsps,
  SkipHireNearMeSizes: LazySkipHireNearMeSizes,
  WasteIcon: LazyWasteIcon,
  WasteTypes: LazyWasteTypes,
  HowItWorks: LazyHowItWorks,
  MicroReview: LazyMicroReview
}
export const componentNames = ["BookingBody","BookingDateItem","BookingDates","BookingFooterControls","BookingHeader","BookingNavigation","BookingNavigationLink","BookingPostcode","BookingSummary","BookingAddOnItem","BookingAttributeValueItem","BookingBasket","BookingBasketItem","BookingCategoryItem","BookingCheckoutAddressSelect","BookingCheckoutForm","BookingCheckoutInput","BookingEquipmentTypeItem","BookingPaymentForm","BookingPaymentMethods","ContactForm","GenericAvailableSkips","GenericBookOnline","GenericBreadcrumbs","GenericFaqs","GenericLocations","GenericMap","GenericSkipHero","GenericSkipPage","GenericSkipPageMap","GenericSkipTypes","GenericWastePage","HomeHero","HomeTimeline","HomeWorker","KnowledgeArticleCard","KnowledgeHelpfulGuides","KnowledgeSearchArticles","LayoutFooter","LayoutNavbar","ModalsGeneric","TrustpilotMicroReview","BlueButton","Divider","IncentivesFourColumnIcons","Faq","Stacked","Feature","FullImage","FullRichText","BlankSpace","BlueWorkerBanner","RichText","Grid","Home","RoundedImageText","TextRoundedImage","GridList","ListItem","Page","OurStory","Cards","HeaderSectionCentered","TextImageLeft","TextImageRight","ThreeUsps","VerticalUsps","SkipHireNearMeSizes","WasteIcon","WasteTypes","HowItWorks","MicroReview","NuxtWelcome","NuxtLayout","NuxtErrorBoundary","ClientOnly","DevOnly","ServerPlaceholder","NuxtLink","NuxtLoadingIndicator","NuxtImg","NuxtPicture","NuxtPage","NoScript","Link","Base","Title","Meta","Style","Head","Html","Body","TrustpilotMicroReview"]