import { CategoryLocationPage } from "~~/types/CategoryLocationPage";

export type CategoryLocationPagesReponse = {
  data: CategoryLocationPage[];
};

export type CategoryLocationPageReponse = {
  data: CategoryLocationPage;
};

export const useCategoryLocationPageStore = defineStore(
  "categoryLocationPage",
  () => {
    const storeCategoryLocationPages = ref<CategoryLocationPage[]>();
    const storeCategoryLocationPage = ref<CategoryLocationPage>();

    async function fetchCategoryLocationPages(): Promise<
      CategoryLocationPage[]
    > {
      const { error, data } =
        await useFetch<CategoryLocationPagesReponse>(
          useJustHireUrl(`/api/category-location-pages`),
          {
            headers: useJustHireHeaders(),
          }
        );

      if (error.value) {
        useHandleJHError(error);
      }

      const categoryLocationPages = useKeysToCamel(data.value?.data);

      storeCategoryLocationPages.value = categoryLocationPages;

      return categoryLocationPages;
    }

    async function fetchCategoryLocationPageByCountySlug(
      slug: string
    ): Promise<CategoryLocationPage> {
      const { error, data } =
        await useFetch<CategoryLocationPageReponse>(
          useJustHireUrl(
            `/api/category-location-pages/${slug}/county-slug`
          ),
          {
            headers: useJustHireHeaders(),
          }
        );

      if (error.value) {
        useHandleJHError(error);
      }

      const categoryLocationPage = useKeysToCamel(data.value?.data);

      storeCategoryLocationPage.value = categoryLocationPage;

      return categoryLocationPage;
    }

    async function fetchCategoryLocationPageByTownAndCountySlug(
      countySlug: string,
      townSlug: string
    ): Promise<CategoryLocationPage> {
      const { error, data } =
        await useFetch<CategoryLocationPageReponse>(
          useJustHireUrl(
            `/api/category-location-pages/${countySlug}/${townSlug}/town-slug`
          ),
          {
            headers: useJustHireHeaders(),
          }
        );

      if (error.value) {
        useHandleJHError(error);
      }

      const categoryLocationPage = useKeysToCamel(data.value?.data);

      storeCategoryLocationPage.value = categoryLocationPage;

      return categoryLocationPage;
    }

    return {
      categoryLocationPages: storeCategoryLocationPages,
      categoryLocationPage: storeCategoryLocationPage,
      fetchCategoryLocationPages,
      fetchCategoryLocationPageByCountySlug,
      fetchCategoryLocationPageByTownAndCountySlug,
    };
  }
);
