import { Attribute } from "~/types/Attribute";

export type ApiResponse = {
  data: Attribute;
};

export const useAttributeStore = defineStore("attribute", () => {
  const storeAttribute = ref<any>();
  const storeAttributes = ref<Attribute[]>();

  async function fetchAttributes(): Promise<Attribute[]> {
    const { error, data } = await useFetch<ApiResponse>(
      useJustHireUrl(`/api/attributes`),
      {
        headers: useJustHireHeaders(),
      }
    );

    if (error.value) {
      useHandleJHError(error);
    }

    const attributes = useKeysToCamel(data.value?.data);

    storeAttributes.value = attributes;

    return attributes as Attribute[];
  }

  async function fetchAttribute(
    attributeUuid?: string
  ): Promise<Attribute> {
    const { error, data } = await useFetch<ApiResponse>(
      useJustHireUrl(`/api/attributes/${attributeUuid}`),
      {
        headers: useJustHireHeaders(),
      }
    );

    if (error.value) {
      useHandleJHError(error);
    }

    const attribute = useKeysToCamel(data.value?.data);

    storeAttribute.value = attribute;

    return attribute as Attribute;
  }

  return {
    attribute: storeAttribute,
    attributes: storeAttributes,
    fetchAttribute,
    fetchAttributes,
  };
});
